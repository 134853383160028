import React from "react";
import { BlogsCard } from "../components/organisms/BlogsItems";
import { Helmet } from "react-helmet";

const Blog = ({ id, link }: any) => {
  console.log("first", link);
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            typeof window !== "undefined"
              ? `${window.location.origin}/blog/${link}`
              : ""
          }
        />{" "}
      </Helmet>
      <BlogsCard link={link} />
    </>
  );
};
export default Blog;
