import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner, Stack } from "react-bootstrap";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/ar";
import WhatsappButton from "../atoms/WhatsappButton";
import FooterMobile from "../MobileViewComponents/Footer/FooterMobile";
import MobileNavigator from "../atoms/MobileNavigator";
import { Media } from "../atoms/Media";
import Header from "./Header";
import ToolBarSection from "./ToolBarSection";
import Footer from "./Footer";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceShareButton,
  XIcon,
} from "react-share";
import { Icon } from "@iconify/react";
import useMediaQuery from "../../hooks/useMediaQuery";
import HeadingUnderLine from "../atoms/HeadingUnderLine";
import findOperatingSystem from "../../hooks/findOperatingSystem";
import { StaticImage } from "gatsby-plugin-image";
import { Grid } from "@mui/material";
import { Link } from "gatsby";


const ContainerStyle = styled(Container)`
  margin: 0 auto;
  width: 80% !important;
  min-height: 80vh;
  @media (max-width: 768px) {
    width: 95% !important;
  }
`;
const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(220deg, #dee2f0 0%, #fbfbfb00 75%);
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
`;

const Wrapper = styled.div`
  width: 100%;
  // margin:0 auto;
  display: flex;
  flex-direction: column;
  // align-items:center;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 1rem;
    margin-top: 19rem;
  }
`;
const ImageLink = styled.a`
  width: 15rem;
  height: 5rem;
  transition: all 250ms;
  background: #07242e;
  border: 2px solid #07242e;
  border-radius: 12px;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.9);
  }
`;

type BlogsProps = {
  // id: number;
  link: string;
};

export const BlogsCard = ({ link }: BlogsProps) => {
  // const BlogData = BlogData.find((element) => element.link === link);
  const isBrowser = () => typeof window !== "undefined";
  const shareLink = `${isBrowser() && window.location.host}/blog/${link}`;
  const { isMobile } = useMediaQuery();
  const { DeviceType } = findOperatingSystem();
  const [error,setError] = useState(false)

  const [loading,setLoading] = useState(false);


  const ButtonContainer = [
    {
      dbBtn: (
        <ImageLink
          href="https://play.google.com/store/apps/details?id=sa.shwra.app"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 mt-md-0"
        >
          <StaticImage
            src="../images/general/google-play-4@3x.png"
            alt="app-image"
            placeholder="blurred"
            objectFit="contain"
            style={{ width: "60%", height: "90%" }}
          />
        </ImageLink>
      ),
    },
    {
      dbBtn: (
        <ImageLink
          href="https://apps.apple.com/sa/app/shwra-%D8%B4%D9%88%D8%B1%D9%89/id1550113344"
          target="_blank"
          rel="noopener noreferrer"
          className="my-5 my-md-0"
        >
          <Stack className="justify-content-center align-items-end ">
            <span style={{ fontSize: "1rem" }}>Available on</span>
            <span
              style={{ fontSize: "1rem", marginTop: -3 }}
              className="fw-bolder"
            >
              App Store
            </span>
          </Stack>

          <StaticImage
            src="../images/Mobile/icon@3x.png"
            alt="logo"
            placeholder="blurred"
            objectFit="contain"
            className="ms-4 ms-md-0"
            style={{ width: "15%", height: "90%", marginInlineStart: "0.5rem" }}
          />
        </ImageLink>
      ),
    },
    {
      dbBtn: (
        <ImageLink
          href="https://appgallery.huawei.com/app/C106460597"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../images/general/huawei-appgallery-black.png"
            alt="app-image"
            placeholder="blurred"
            style={{ width: "80%", height: "90%" }}
          />
        </ImageLink>
      ),
    },
  ];

  const apiUrl = `https://blogs-be.azurewebsites.net/Posts/GetByUniqueUrlName?UniqueUrlName=${link}`
  const [BlogData,setBlogData] = useState([]);

  const getBlogs = async ()=>{
    setLoading(true);
    await fetch(apiUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('this is data',data);
      setBlogData(data)
    })
    .catch(error => {
      console.error('Error:', error);
      setError(true)
    });
    setLoading(false);

  }

  useEffect(()=>{
getBlogs();
  },[])
  

  const BlogsCard2 = ({
    head,
    dec,
    content,
    imageCover,
    imageThumb,
    publishedAt,
    id,
    latest,
    link,
    imageUrl
  }: BlogsProps) => {
    return (
    
      <div style={{ margin: "20" }}>
       {latest ?  <>
        <Media greaterThan="sm">
          <img
            src={imageUrl}
            alt={head}
            placeholder="blurred"
            style={
              {
                // width: "1216px",
                // height: "516px"
                objectFit:'fill',
                width:'100%',
                height:'100%'
              }
            }
          />
        </Media>
        <Media at="sm">
          <img
            src={imageUrl}
            alt={head}
            placeholder="blurred"
            // objectFit='fill'
            style={
              {
                // width: "1216px",
                // height: "516px"
                objectFit:'fill',
                width:'100%',
                height:'100%'
              }
            }
          />
        </Media>
      </> :
       <img
        src={imageThumb}
        alt={head}
        placeholder="blurred"
        style={{
          maxHeight: "600px",
          objectFit:'fill',
          width:'100%',
          height:'100%'
        }}
      /> }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          <p
            style={{
              fontSize: "1.5rem",
              direction: "rtl",
              color: "#DDB669",
              marginTop: "0rem",
              marginBottom: "0rem",
            }}
          >
            {moment(publishedAt).format("LL")}
          </p>
          {/* <a href={`/blog/${link}`}  rel="noopener noreferrer"> */}

          <a
            href={`/blog/${link}`}
            rel="noopener noreferrer"
            style={{
              fontSize: "2rem",
              direction: "rtl",
              marginBottom: "0rem",
              marginTop: "0rem",
              color: "black",
            }}
          >
            {head}
          </a>
          {/* </a> */}

          <p
            style={{
              fontSize: "1.5rem",
              direction: "rtl",
              marginTop: "0rem",
              marginBottom: "0rem",
              color: "#475467",
            }}
          >
            {dec}
          </p>
          <a
            href={`/blog/${link}`}
            // target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              flexDirection: "row",
              direction: "rtl",
              alignItems: "center",
              marginTop: "1rem",
              gap: "0.5rem",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                direction: "rtl",
                color: "#DDB669",
                marginTop: "0rem",
                marginBottom: "0rem",
              }}
            >
              {"قراءة المقال"}
            </p>
            <Icon color="#DDB669" icon="akar-icons:arrow-up-left" />
          </a>
        </div>
      </div>
    );
  };
//////


if(loading)
  {
   return <div style={{
      width:'100%',
      height:'100vh',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
    }}>
          <Spinner  style={{
            width:'40px',
            height:'40px',
            color:'#DDB669'
          }}/>
    </div>
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content={BlogData?.keyWords} />
        <meta name="description" content={BlogData?.meta} />
        <Helmet>
        <link
          rel="canonical"
          href={
            typeof window !== "undefined"
              ? `${window.location.origin}/blog/${link}`
              : ""
          }
        />{" "}
      </Helmet>
        <title>{BlogData?.title}</title>

        {BlogData?.markupSchema && BlogData?.markupSchema?.map((schema:any)=> 
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
        )}
      </Helmet>
      <ContainerStyle fluid>
        <Media at="sm">
          <MobileNavigator link="/" title="المدونة" />
          <div style={{ marginTop: "1rem", alignItems: "flex-start" }}></div>

          <div style={{ margin: "20" }}>

        {error &&  <StaticImage
                  src="../images/blogs/Not-found.png"
                  alt="app-image"
                  placeholder="blurred"
                  objectFit="contain"
                  style={{ width: '20%', height: '90%', marginLeft: "auto", marginRight: "auto", display: "flex" }} />}
            
          <>
        <Media greaterThan="sm">
          <img
            src={BlogData?.imageUrl}
            alt={BlogData?.head}
            placeholder="blurred"
            style={
              {
                // width: "1216px",
                // height: "516px"
                objectFit:'fill',
                width:'100%',
                height:'100%'
              }
            }
          />
        </Media>
        <Media at="sm">
          <img
            src={BlogData?.imageUrl}
            alt={BlogData?.head}
            placeholder="blurred"
            // objectFit='fill'
            style={
              {
                // width: "1216px",
                // height: "516px"
                objectFit:'fill',
                width:'100%',
                height:'100%'
              }
            }
          />
        </Media>
      </>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "2rem",
                direction: "rtl",
              }}
            >
              <p
                style={{
                  fontSize: "1.5rem",
                  direction: "rtl",
                  color: "#DDB669",
                  marginTop: "0rem",
                  marginBottom: "0rem",
                  textAlign: "center",
                }}
              >
                {moment(BlogData?.publishedAt).format("LL")}
              </p>
              <h2
                style={{
                  fontSize: "3rem",
                  direction: "rtl", 
                  marginBottom: "0rem",
                  marginTop: "0rem",
                  textAlign: "center",
                }}
              >
                {BlogData?.head}
              </h2>
              <div className="blog-content" dangerouslySetInnerHTML={{ __html: BlogData?.content }} />
              <div>
                <a
                  target="new"
                  href={DeviceType === -1 ? "https://apps.apple.com/sa/app/shwra-%D8%B4%D9%88%D8%B1%D9%89/id1550113344" : "http://onelink.to/shwra"}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                    direction: "rtl",
                    fontWeight: "600",
                  }}
                >
                  <h3 style={{ textDecorationLine: "underline" }}>
                    {BlogData?.cta } 
                  </h3>
                </a>
                <br></br>
              </div>
            </div>
          </div>
        </Media>

        {/* ---- */}

        <Media greaterThan="sm">
          <ToolBarSection business={false} />
          <Header />
          <div
            style={{
              marginTop: "15rem",
              alignItems: "flex-start",
              direction: "rtl",
            }}
          ></div>
          <div style={{ margin: "20", direction: "rtl" }}>
          { error && <StaticImage
                  src="../images/blogs/Not-found.png"
                  alt="app-image"
                  placeholder="blurred"
                  objectFit="contain"
                  style={{ width: '20%', height: '90%', marginLeft: "auto", marginRight: "auto", display: "flex" }} /> }
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "2rem",
              }}
            >
              <p
                style={{
                  fontSize: "1.5rem",
                  direction: "rtl",
                  color: "#DDB669",
                  marginTop: "0rem",
                  marginBottom: "0rem",
                  textAlign: "center",
                }}
              >
                {moment(BlogData?.publishedAt).format("LL")}
              </p>
              <h1
                style={{
                  fontSize: "3rem",
                  direction: "rtl",
                  marginBottom: "2rem",
                  marginTop: "0rem",
                  textAlign: "center",
                }}
              >
                {BlogData?.head}
              </h1>
              <>
        <Media greaterThan="sm">
          <img
            src={BlogData?.imageUrl}
            alt={BlogData?.head}

            placeholder="blurred"
            style={
              {
                // width: "1216px",
                // height: "516px"
                objectFit:'fill',
                width:'100%',
                height:'100%'
              }
            }
          />
        </Media>
        <Media at="sm">
          <img
            src={BlogData?.imageUrl}
            alt={BlogData?.head}

            placeholder="blurred"
            // objectFit='fill'
            style={
              {
                // width: "1216px",
                // height: "516px"
                objectFit:'fill',
                width:'100%',
                height:'100%'
              }
            }
          />
        </Media>
      </>
              <div
              className="blog-content"
                style={{ direction: "rtl", marginBlockStart: "1rem" }}
                dangerouslySetInnerHTML={{ __html: BlogData?.content }}
              />
              <div>
                <a
                  target="new"
                  href={DeviceType === -1 ? "https://apps.apple.com/sa/app/shwra-%D8%B4%D9%88%D8%B1%D9%89/id1550113344" : "http://onelink.to/shwra"}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontSize: "2rem",
                    direction: "rtl",
                    fontWeight: "600",
                  }}
                >
                  <span style={{ textDecorationLine: "underline" }}>
                    {BlogData?.cta}
                  </span>
                </a>
                <br></br>
              </div>
            </div>
          </div>
        </Media>

{/* ---- sepereate code ---- */}
        <div
          style={{ borderTop: "1px solid #ebebeb", marginBlockStart: "1rem" }}
        >
          <h4
            style={{
              fontSize: "2rem",
              direction: "rtl",
              marginBlockStart: "3rem",
            }}
          >
            {"مشاركة المقال"}
          </h4>
          <div
            style={{
              marginBlockStart: "1rem",
              direction: "rtl",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FacebookShareButton
                style={{ marginInlineEnd: "1rem" }}
                url={shareLink}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={isMobile ? 20 : isMobile ? 20 : 32} round />
              </FacebookShareButton>

              <TwitterShareButton
                style={{ marginInlineEnd: "1rem" }}
                url={shareLink}
                className="Demo__some-network__share-button"
              >
                <XIcon size={isMobile ? 20 : 32} round />
              </TwitterShareButton>

              <WhatsappShareButton
                style={{ marginInlineEnd: "1rem" }}
                url={shareLink}
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={isMobile ? 20 : 32} round />
              </WhatsappShareButton>

              <LinkedinShareButton
                style={{ marginInlineEnd: "1rem" }}
                url={shareLink}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={isMobile ? 20 : 32} round />
              </LinkedinShareButton>

              <EmailShareButton
                style={{ marginInlineEnd: "1rem" }}
                url={shareLink}
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={isMobile ? 20 : 32} round />
              </EmailShareButton>
            </div>
            <a
              onClick={() => {
                navigator.clipboard.writeText(shareLink);
              }}
              style={{
                width: "120px",
                height: "40px",
                backgroundColor: "white",
                border: "1px solid #ebebeb",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.8rem",
                cursor: "copy",
              }}
            >
              <Icon icon="akar-icons:copy" />
              <p
                style={{
                  fontSize: "1.5rem",
                  direction: "rtl",
                  marginTop: "0rem",
                  marginBottom: "0rem",
                }}
              >
                {"نسخ الرابط"}
              </p>
            </a>
          </div>
        </div>

        <Media style={{ borderBottom: "2px solid #dee2f070" }} greaterThan="sm">
          <div
            style={{ marginBlockStart: "5rem", marginBlockEnd: "5rem" }}
            dir="rtl"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className=""
            >
              <div>
                <blogsUnderline title="اطلب خدمة قانونية" />
                <h4 className="BlogsUnderline">اطلب خدمة قانونية </h4>

                <span className="text-center fs-4">
                  حمل التطبيق واحصل على خدمتك القانونية
                </span>
                <div
                  style={{ marginBlockStart: "2rem" }}
                  className="d-flex align-items-center flex-column flex-md-row gap-md-3"
                >
                  {DeviceType === -1
                    ? ButtonContainer.map((i, a) => {
                        return <>{i.dbBtn}</>;
                      })
                    : ButtonContainer[DeviceType].dbBtn}
                </div>
              </div>
              <StaticImage
                src={"../../images/blogs/app-shwra.png"}
                alt="app-image"
                placeholder="blurred"
                //   objectFit="unset"
                style={{ width: "50%", height: "90%" }}
              />
            </div>
          </div>
        </Media>

        <Media style={{ borderBottom: "2px solid #dee2f070" }} at="sm">
          <div
            style={{ marginBlockStart: "5rem", marginBlockEnd: "5rem" }}
            dir="rtl"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className=""
            >
              <div>
                <blogsUnderline title="اطلب خدمة قانونية" />
                <h2 className="BlogsUnderline">اطلب خدمة قانونية </h2>

                <span className="text-center fs-4">
                  حمل التطبيق واحصل على خدمتك القانونية
                </span>
                <div
                  style={{ marginBlockStart: "2rem" }}
                  className="d-flex align-items-center flex-column flex-md-row gap-md-3"
                >
                  {DeviceType === -1
                    ? ButtonContainer.map((i, a) => {
                        return <>{i.dbBtn}</>;
                      })
                    : ButtonContainer[DeviceType].dbBtn}
                </div>
              </div>
              <StaticImage
                src={"../../images/blogs/app-shwra-mobile.png"}
                alt="app-image"
                placeholder="blurred"
                //   objectFit="fill"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </Media>
        {BlogData?.length > 1 && (
          <div style={{ marginTop: "5rem", alignItems: "flex-start" }}>
            <h4
              style={{
                fontSize: "2rem",
                direction: "rtl",
                marginBottom: "4rem",
              }}
            >
              مقالات اخرى
            </h4>
            <Grid
              direction={"row-reverse"}
              rowSpacing={5}
              columnSpacing={5}
              container
            >
              {BlogData?.filter((element) => element.link !== link)
                ?.slice(0, 3)
                ?.map((service, index) => {
                  const {
                    head,
                    content2,
                    content,
                    imageCover,
                    imageThumb,
                    publishedAt,
                    link,
                    id,
                  } = service;

                  return (
                    <Grid item xs={12} sm={6} lg={4} md={2}>
                      <BlogsCard2
                        dec={content2}
                        head={head}
                        content={content}
                        imageCover={imageCover}
                        imageThumb={imageThumb}
                        publishedAt={publishedAt}
                        link={link}
                        id={id}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <a
              href={`/blogs`}
              rel="noopener noreferrer"
              style={{
                width: "17rem",
                height: "5rem",
                backgroundColor: "#DDB669",
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "1rem",
                marginBlockStart: "3rem",
              }}
            >
              <h4
                style={{ fontSize: "1.5rem", direction: "rtl", color: "white" }}
              >
                عرض جميع المقالات
              </h4>
            </a>
          </div>
        )}
      </ContainerStyle>

      <div dir="rtl" style={{ marginTop: "3rem" }}>
        <Media at="sm">
          <FooterMobile />
        </Media>
        <Media greaterThan="sm">
          <Footer />
        </Media>
      </div>

      <Gradient></Gradient>
      <WhatsappButton />
    </>
  );
};
